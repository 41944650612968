$tabletBreakpoint: 768px;
$desktopBreakpoint: 1025px;

$secondary: #96c11f;
$primary: #5dc3dc;
$white: #fff;

$fontFamily: arial;

$bigFontSize: 2rem;
$mediumFontSize: 1.2rem;
$smallFontSize: 1.1rem;

$boxShadow: 0 0 10px rgba(128, 128, 128, 0.473);
$boxShadowDark: 0 0 10px rgb(128, 128, 128);
