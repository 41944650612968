@import 'styles/mixins.scss';
.offers-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
  @media screen and (min-width: $desktopBreakpoint) {
    flex-direction: row;
  }
  &__list-container {
    width: 100%;
    max-width: 1100px;
    margin: auto;
    list-style-type: none;
    height: 75vh;
    overflow-y: scroll;
  }
  &__filter-box {
    @media screen and (min-width: $desktopBreakpoint) {
      display: none;
    }
    &--hidden {
      display: none;
      @media screen and (min-width: $desktopBreakpoint) {
        display: block;
      }
    }
  }
  &__box {
    padding: 10px;
  }
}
