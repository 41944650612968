@import "styles/variables.scss";
@import "styles/mixins";

.contact-page {
  display: flex;
  flex-direction: column;
  &__header {
    width: 300px;
    margin: auto;
    text-align: center;
    font-size: 2.2rem;
    @include underline();
    @media screen and (min-width: $tabletBreakpoint) {
      width: 350px;
      margin: auto;
      text-align: center;
      @include underline(350px);
    }
  }
  &__upper {
    width: 100%;
    height: 800px;
    margin-bottom: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    @media screen and (min-width: $tabletBreakpoint) {
      height: 720px;
      margin-bottom: 0;
    }
    @media screen and (min-width: $desktopBreakpoint) {
      flex-direction: row;
      justify-content: center;
      height: 640px;
    }
    &__contact-data {
      width: 100%;
      height: 400px;
      display: flex;
      flex-direction: column;
      font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
      font-size: 20px;
      &__header {
        font-weight: 600;
      }
      @media screen and (min-width: $tabletBreakpoint) {
        margin: auto;
        width: 450px;
        display: flex;
        align-items: center;
      }
      @media screen and (min-width: $desktopBreakpoint) {
        margin: 0;
        max-width: 450px;
        margin-top: 80px;
        text-align: left;
        border-radius: 10px;
        box-shadow: $boxShadow;
        background-color: #96c11fd0;
        color: white;
      }
      &__wrapper {
        padding: 15px;
      }
      &__laptop {
        height: 250px;
        width: 390px;
        margin: auto;
        @media screen and (min-width: $tabletBreakpoint) {
          width: 450px;
          height: 300px;
          border-radius: 10px;
          margin: auto;
        }
      }
    }
    &__form {
      height: 500px;
      margin-top: 240px;
      @media screen and (min-width: $tabletBreakpoint) {
        width: 100%;
        grid-column: span 2;
      }
      @media screen and (min-width: $desktopBreakpoint) {
        margin: 0;
        margin-top: 150px;
        margin-left: 50px;
        width: 450px;
        height: 550px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        border-radius: 10px;
        box-shadow: $boxShadow;
        background-color: #96c11fd0;
        color: white;
      }
      &__picture {
        display: none;
        @media screen and (min-width: $desktopBreakpoint) {
          display: block;
          border-radius: 10px;
        }
      }
      &__p {
        width: 300px;
        margin: auto;
        font-family: $fontFamily;
        text-align: center;
        font-weight: 500;
        margin-top: 90px;
        @media screen and (min-width: $tabletBreakpoint) {
          width: 400px;
          font-size: 20px;
          margin-top: 120px;
        }
        @media screen and (min-width: $desktopBreakpoint) {
          font-weight: 600;
          margin-top: 30px;
        }
      }
      &__btn {
        width: 150px;
        margin: auto;
        margin-top: 15px;
        @media screen and (min-width: $desktopBreakpoint) {
          margin-top: 5px;
        }
      }
    }
  }
  &__bottom {
    width: 100%;
    max-width: 1200px;
    margin: auto;
    margin-top: 100px;
    @media screen and (min-width: $tabletBreakpoint) {
      margin-top: 220px;
    }
    @media screen and (min-width: $desktopBreakpoint) {
      margin-top: 100px;
    }
    &__text {
      display: none;
      @media screen and (min-width: $desktopBreakpoint) {
        display: block;
        width: 300px;
        margin-left: 200px;
        margin-bottom: 80px;
        margin-top: 200px;
        font-family: $fontFamily;
        font-weight: 600;
        text-align: center;
      }
    }
  }
}
