.loading-container {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 1000;
  width: 100vw;
  height: 100vh;
  background-color: rgba(128, 128, 128, 0.85);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  &__message {
    display: block;
    margin-top: 20px;
    font-size: 40px;
    font-weight: bold;
  }
}

.lds-dual-ring {
  display: inline-block;
  width: 80px;
  height: 80px;
  position: relative;
}
.lds-dual-ring:after {
  content: ' ';
  display: block;
  width: 64px;
  height: 64px;
  border-radius: 50%;
  border: 6px solid black;
  border-color: black transparent black transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}
.lds-dual-ring--completed:after {
  border-color: black;
  animation: none;
}
.lds-dual-ring--completed::before {
  position: absolute;
  top: 45%;
  left: 45%;
  transform: translate(-50%, -50%);
  content: '\2713';
  font-weight: bold;
  font-size: 40px;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
