@import "styles/variables.scss";
.hint {
  &__label {
    font-family: $fontFamily;
    color: $secondary;
  }
  &__list {
    background-color: white;
    position: absolute;
    top: 65px;
    width: 100%;
    border-radius: 5px;
    box-shadow: $boxShadow;
    z-index: 10;
    max-height: 300px;
    overflow-y: scroll;
  }
  &__item {
    list-style: none;
    padding: 5px 10px;
    border-bottom: 0.5px solid rgba(128, 128, 128, 0.164);
    cursor: "pointer";
    &:hover {
      background-color: lightblue;
    }
    &:first-child {
      border-top-right-radius: 5px;
      border-top-left-radius: 5px;
    }
    &:last-child {
      border-bottom-right-radius: 5px;
      border-bottom-left-radius: 5px;
    }
  }
  &__input {
    height: 38px !important;
    margin-bottom: 0 !important;
  }
}
