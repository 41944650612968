@import "/src/styles/variables.scss";

.hamburger-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: flex-start;
  width: 40px;
  height: 40px;
  overflow: hidden;
  margin: 25px 25px auto auto;
  box-sizing: border-box;
  &__block {
    display: block;
    background-color: white;
    width: 100%;
    height: 3px;
    border-radius: 20px;
    transform-origin: 1px;
    transition: all 0.3s linear;
    &--rotate-left {
      transform: rotate(45deg);
    }
    &--rotate-right {
      transform: rotate(-45deg);
    }
    &--move-right {
      opacity: 0;
      transform: translateX(100%);
    }
  }
  @media screen and (min-width: $desktopBreakpoint) {
    display: none;
  }
}
