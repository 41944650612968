@import "styles/variables";

.footer-container {
  background-color: $primary;
  height: fit-content;
  width: 100%;
  box-sizing: border-box;
  margin-top: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-family: $fontFamily;
  font-size: 1rem;
  font-weight: 500;
  color: white;
  padding: 5px;
  @media screen and (min-width: $tabletBreakpoint) {
    flex-direction: row;
    align-items: center;
  }

  &__logo {
    height: 80px;
    width: 180px;
    text-align: center;
    margin: auto;
    color: black;
    @media screen and (min-width: $tabletBreakpoint) {
      margin: 0;
      height: 90px;
      width: 200px;
    }
    &__pic {
      width: 100%;
      height: 100%;
    }
  }
  &__informations {
    height: 200px;
    width: 100%;
    display: flex;
    flex-direction: column;
    @media screen and (min-width: $tabletBreakpoint) {
      width: 600px;
    }
    &__upper {
      margin-top: 12px;
      height: 120px;
      width: 100%;
      display: flex;
      justify-content: center;
      &__emails {
        width: 50%;
        height: 100%;
        display: flex;
        text-align: center;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
      }
      &__open-hours {
        width: 50%;
        height: 100%;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
      }
    }
    &__bottom {
      height: 80px;
      width: 100%;
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      &__socials {
        width: 80%;
        padding: 8px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        @media screen and (min-width: $tabletBreakpoint) {
          width: 50%;
        }
      }
    }
    &__header {
      font-weight: 700;
      display: flex;
      justify-content: center;
      align-items: center;
      text-decoration: none;
      color: white;
      &__social-icon {
        width: 30px;
        height: 30px;
      }
    }
  }
}
