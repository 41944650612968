@import "styles/variables";

.about {
  text-align: center;
  padding: 30px;
  display: flex;
  flex-direction: column;
  max-width: 2000px;
  margin: auto;
  @media screen and (min-width: $desktopBreakpoint) {
    flex-direction: row;
  }
  &__pictures {
    order: 2;
    @media screen and (min-width: $tabletBreakpoint) {
      display: flex;
      justify-content: space-around;
      flex-direction: row;
    }
    @media screen and (min-width: $desktopBreakpoint) {
      order: 0;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-top: 400px;
    }

    &__wrapper {
      text-align: center;
      @media screen and (min-width: $desktopBreakpoint) {
        margin: auto;
        display: flex;
        flex-direction: column;
      }
    }
    &__pic-container {
      width: 150px;
      height: 260px;
      margin: auto;

      @media screen and (min-width: $tabletBreakpoint) {
        width: 250px;
        height: 360px;
      }
      @media screen and (min-width: $desktopBreakpoint) {
        width: 250px;
        height: 360px;
      }
      &__pic {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 25px;
      }
    }
  }
  &__center {
    text-align: center;
    @media screen and (min-width: $desktopBreakpoint) {
      margin: auto;
      width: 2500px;
      margin-top: 0;
    }
  }
  &__paragraph {
    width: 100%;
    max-width: 800px;
    margin: auto;
    margin-top: 20px;
    font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
      "Lucida Sans", Arial, sans-serif;
    font-size: 20px;
  }
  &__header {
    font-family: $fontFamily;
    margin-bottom: 30px;
  }
  &__h3 {
    font-family: $fontFamily;
    font-size: 35px;
    margin-top: 40px;
    margin-bottom: 60px;
    text-align: center;
  }
  &__logo {
    margin-top: 50px;
    margin-bottom: 20px;
    width: 100%;
    max-width: 400px;
  }
}
