@import "styles/variables.scss";

.select-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  &__label {
    font-family: $fontFamily;
    font-size: $smallFontSize;
    color: $secondary;
    font-weight: 600;
    margin-bottom: 5px;
  }
  &__element {
    height: 38px;
    border: solid 1px $primary;
    border-radius: 8px;
    padding: 0 5px;
    font-family: $fontFamily;
    font-size: 14px;
    font-weight: 600;
    color: rgb(90, 90, 90);
    &:hover {
      cursor: pointer;
    }
    &:focus {
      border: solid 1px $secondary;
    }
  }
}
