@import "styles/variables";
@import "styles/global";

.header-wrapper {
  position: relative;
  height: 80px;
}

.header-container {
  background-color: $primary;
  position: fixed;
  height: 80px;
  width: 100%;
  top: 0;
  z-index: 10;
  @media screen and (min-width: $desktopBreakpoint) {
    display: flex;
    align-items: center;
  }
  &__logo {
    display: none;
    @media screen and (min-width: $desktopBreakpoint) {
      display: block;
      width: 150px;
      height: 60px;
      margin-left: 10px;
      &:hover {
        cursor: pointer;
      }
    }
  }
  &__list {
    width: fit-content;
    height: 100%;
    display: none;
    list-style-type: none;
    @media screen and (min-width: $desktopBreakpoint) {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: auto;
    }
  }
  &__list-item {
    display: block;
    text-align: center;
    z-index: 20;
    position: relative;
    margin-right: 30px;
    box-sizing: border-box;
    min-width: 100px;
    cursor: pointer;
    background-color: transparent;
    text-decoration: none;
    color: $secondary;
    font-family: $fontFamily;
    font-size: 1.5rem;
    color: #ffff;
    font-weight: 600;
    &:hover::after {
      content: "";
      display: block;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      width: 100%;
      border-top: 1px solid #ffff;
      animation: fancyHover 2s;
      animation-fill-mode: forwards;
    }
    &--thick {
      min-width: 0;
    } 
  }
}
