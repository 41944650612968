@import "styles/variables.scss";

.filter-container {
  width: 90%;
  max-width: 500px;
  background-color: rgba(220, 239, 250, 0.61);
  box-shadow: $boxShadow;
  margin: 0 auto 30px;
  padding: 20px 30px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  border-radius: 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media screen and (min-width: $desktopBreakpoint) {
  }
  &__element {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 15px 0;
    &--narrow {
      margin-bottom: 0;
      @media screen and (min-width: $desktopBreakpoint) {
        margin-top: 40px;
        margin-right: 0;
      }
    }
    @media screen and (min-width: $desktopBreakpoint) {
      margin-right: 20px;
      margin-bottom: 0;
    }
    &__input {
      height: 40px !important;
    }
  }
}

.filter-primary-input {
  width: 100%;
  min-width: 300px
}

.primary-input-label{
  color: $secondary
}
