@import "styles/variables.scss";

@keyframes fancyHover {
  0% {
    width: 5%;
  }
  25% {
    width: 80%;
  }
  100% {
    width: 80%;
  }
}

h1 {
  font-size: 2rem;
  font-weight: 600;
  font-family: $fontFamily;
  margin-bottom: 10px;
}
h2 {
  font-size: 1.8rem;
  font-weight: 600;
  font-family: $fontFamily;
  margin-bottom: 10px;
}
h3 {
  font-size: 1.6rem;
  font-weight: 600;
  font-family: $fontFamily;
  margin-bottom: 10px;
}
h4 {
  font-size: 1.5rem;
  font-weight: 600;
  font-family: $fontFamily;
  margin-bottom: 10px;
}
