@import "styles/variables";

.contact-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  padding: 20px;
  background-color: rgba(220, 239, 250, 0.61);
  box-sizing: border-box;
  border-radius: 10px;
  @media screen and (min-width: $desktopBreakpoint) {
    width: 100%;
    max-width: 800px;
  }

  &__title {
    font-size: 25px;
    font-family: $fontFamily;
    font-weight: 600;
  }
  &__personal {
    margin-top: 10px;
    width: 100%;
  }
  &__contact-data {
    width: 100%;
    @media screen and (min-width: $desktopBreakpoint) {
      display: flex;
      justify-content: space-between;
    }
    &__item {
      width: 100%;
      margin-bottom: 10px;
      @media screen and (min-width: $desktopBreakpoint) {
        width: 49%;
      }
    }
  }
  &__message {
    margin-bottom: 10px;
    width: 100%;
    height: 150px;
  }
  &__agreement {
    display: flex;
    justify-content: center;
  }
  &__button {
    width: 100%;
    height: 40px;
    margin-top: 10px;
    @media screen and (min-width: $desktopBreakpoint) {
      margin-top: 10px;
      width: 300px;
    }
  }
}
