@import 'styles/mixins.scss';

.checkbox-container {
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  position: relative;
  &__input {
    @include CheckboxInput;
    min-width: 30px;
    &--error {
      outline: 2px solid red;
    }
  }
  &__label {
    font-size: 1.1rem;
  }
  &__error {
    position: absolute;
    top: -20px;
    color: red;
    font-weight: bold;
    @media screen and (min-width: $desktopBreakpoint) {
      left: -30px;
    }
  }
}
