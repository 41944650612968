@import 'styles/variables';
@import 'styles/mixins';

.application-form-page {
  max-width: 1500px;
  width: 90%;
  height: 100%;
  margin: auto;
  display: flex;
  flex-direction: column;
  &__header {
    width: 100%;
    font-size: 1.8rem;
    @include underline();
    @media screen and (min-width: $desktopBreakpoint) {
      margin-left: 40px;
      @include underline(350px);
    }
  }
  @media screen and (min-width: $desktopBreakpoint) {
    width: 100%;
    flex-direction: row;
    flex-wrap: wrap;
  }
  &__basic-info {
    display: flex;
    flex-direction: column;
    @media screen and (min-width: $desktopBreakpoint) {
      width: 45%;
      height: 100%;
      margin: 0 auto;
    }
    &--moved-left {
      @media screen and (min-width: $desktopBreakpoint) {
        margin: 0 auto 0 33px;
      }
    }
    &--wide {
      @media screen and (min-width: $desktopBreakpoint) {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
      }
    }
    &--margin-top {
      margin-top: 20px;
    }
  }
  &__multi-inputs-label {
    font-weight: 600;
    margin-top: 15px;
    margin-bottom: 10px;
    &:first-child {
      margin-top: 0;
    }
  }
  &__button {
    margin: 60px auto;
    max-width: 200px;
  }
}
