@import "styles/mixins.scss";

.radio-container {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  &__input {
    @include RadioInput;
  }
  &__label {
    font-size: 1.1rem;
  }
}
