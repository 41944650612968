@import "styles/variables.scss";

.for-family {
  display: flex;
  flex-direction: column;
  max-width: 2000px;
  margin: auto;
  @media screen and (min-width: $desktopBreakpoint) {
    flex-direction: row;
  }
  &__page {
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    @media screen and (min-width: $desktopBreakpoint) {
      width: 80%;
      margin-top: 50px;
      margin-left: 100px;
      margin-bottom: 50px;
    }
    &__section-header {
      width: 95%;
      margin: auto;
      margin-left: 20px;
    }
    &__section-paragraph {
      margin: 10px 0 auto 20px;
      font-size: $smallFontSize;
      text-align: left;
      @media screen and (min-width: $desktopBreakpoint) {
        width: 1000px;
      }
    }
    &__list {
      margin: 10px 0 0 35px;
      font-size: $smallFontSize;
      width: 80%;
      @media screen and (min-width: $desktopBreakpoint) {
        width: 1000px;
      }
    }
  }
  .for-family__picture {
    margin-top: 20px;
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
    @media screen and (min-width: $desktopBreakpoint) {
      display: block;
    }
    &__pic {
      width: 250px;
      border-radius: 10px;
      @media screen and (min-width: $desktopBreakpoint) {
        margin: 0;
        width: 600px;
        margin-right: 100px;
        margin-top: 50px;
      }
    }
  }
}
