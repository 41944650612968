@import 'styles/variables';

.wrapper-container {
  width: 90%;
  height: 100%;
  margin: 0 auto;
  min-height: 400px;
  box-sizing: border-box;
  @media screen and (min-width: $desktopBreakpoint) {
    width: 620px;
    height: 417px;
    width: 100%;
  }
}
.section-center {
  height: 100%;
  min-height: 400px;
  width: 100%;
  text-align: center;
  display: flex;
  overflow: hidden;
  position: relative;
}
.apartament-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: bottom;
  transition: transform 2s linear;
}
.icon {
  height: 100%;
  width: 100%;
  color: rgba(0, 0, 0, 0.3);
}
.prev,
.next {
  margin: 0;
  padding: 0;
  position: absolute;
  top: 30%;
  transform: translateY(-50%);
  z-index: 3;
  background: transparent;
  width: 60px;
  height: 100px;
  outline: none;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}
.prev:hover,
.next:hover {
  background: rgba(128, 128, 128, 0.2);
}
.prev {
  left: 0;
}
.next {
  right: 0;
}
.image-container {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: all 0.5s linear;
}
article.activeSlide {
  opacity: 1;
  transform: translateX(0);
}
article.lastSlide {
  transform: translateX(-100%);
}
article.nextSlide {
  transform: translateX(100%);
}

.zoom-in {
  overflow: hidden;
}

.daily-container {
  @media screen and (min-width: $desktopBreakpoint) {
    display: flex;
    width: 80%;
    flex-wrap: wrap;
    justify-content: center;
    margin: auto;
  }
  &__title {
    width: 100%;
    margin: 0 auto 20px;
    color: $secondary;
  }
  &__image-box {
    height: 100%;
    @media screen and (min-width: $desktopBreakpoint) {
      width: 50%;
    }
  }
  &__content-box {
    padding: 0;
    width: 100%;
    @media screen and (min-width: $desktopBreakpoint) {
      width: 50%;
    }
  }
  &__details-item {
    font-family: $fontFamily;
    font-size: 0.8rem;
    font-weight: 600;
    color: rgb(51, 51, 51);
    width: 45%;
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
  }
  &__details-value {
    color: rgb(128, 128, 128);
    width: 100%;
  }
  &__button-box {
    display: flex;
    width: 40%;
    margin: 0 30px 0 auto;
  }
}

.freepik-url {
  font-size: 10px;
  color: black;
  opacity: 0.2;
}
