@import 'variables.scss';

@mixin CheckboxInput {
  // min-width: 20px;
  // min-height: 20px;
  width: 30px;
  height: 30px;
  margin-right: 5px;
}

@mixin RadioInput {
  width: 30px;
  height: 30px;
  margin-right: 5px;
}

@mixin underline($width: 100%) {
  position: relative;
  &::after {
    content: '';
    height: 2px;
    width: $width;
    background-color: $secondary;
    position: absolute;
    bottom: 0;
    left: -5px;
    animation: move-right 2s;
  }
}

.primary-button {
  width: 100%;
  min-width: 30px;
  min-height: 35px;
  height: 100%;
  border-radius: 10px;
  color: white;
  font-family: $fontFamily;
  font-weight: 700;
  &--green {
    background-color: $secondary;
    &:hover {
      background-color: $primary;
      cursor: pointer;
      transition: all 0.3s ease-in-out 0s;
    }
  }

  &--blue {
    background-color: $primary;
    &:hover {
      background-color: $secondary;
      cursor: pointer;
      transition: all 0.3s ease-in-out 0s;
    }
  }
}
.shadow-button {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  font-weight: 700;
  font-family: $fontFamily;
  color: white;
  &--blue {
    background-color: $primary;
    &:hover {
      background-color: $secondary;
      cursor: pointer;
      box-shadow: 0 8px 12px 0 rgba(0, 0, 0, 0),
        0 10px 10px 0 rgba(0, 0, 0, 0.19);
      transition: all 0.3s ease-in-out 0s;
    }
  }
  &--green {
    background-color: $secondary;
    &:hover {
      background-color: $primary;
      cursor: pointer;
      box-shadow: 0 8px 12px 0 rgba(0, 0, 0, 0),
        0 10px 10px 0 rgba(0, 0, 0, 0.19);
      transition: all 0.3s ease-in-out 0s;
    }
  }
}

.primary-input {
  width: 100%;
  height: 100%;
  border: solid 1px $primary;
  margin-bottom: 15px;
  border-radius: 8px;
  text-align: left;
  box-sizing: border-box;
  padding: 10px;
  color: black;
  font-family: $fontFamily;
  font-weight: 500;
  max-height: 60px;
  &::placeholder {
    font-weight: 700;
    font-size: 16px;
    font-family: $fontFamily;
    color: grey;
    text-align: center;
  }
  &:focus {
    border: solid 1px $secondary;
  }
  &--error {
    border: solid 1px red;
  }
}

.primary-text-area {
  width: 100%;
  height: 100%;
  border: solid 1px $primary;
  border-radius: 8px;
  resize: none;
  overflow: hidden;
  font-family: $fontFamily;
  font-weight: 600;
  &::placeholder {
    text-align: center;
    padding: 10px;
  }
  &:hover {
    border: solid 1px $secondary;
  }
}

@keyframes move-right {
  0% {
    width: 0px;
  }
  100% {
    width: 350px;
  }
}
