@import 'styles/variables.scss';

.input-container {
  position: relative;
}

.input-label {
  font-family: $fontFamily;
  font-size: $mediumFontSize;
  font-weight: 600;
}

.input-error {
  position: absolute;
  right: 0;
  color: red;
  font-weight: bold;
  bottom: -5px;
}
