@import '/src/styles/variables.scss';

.language-container {
  display: flex;
  justify-content: center;
  position: relative;
  width: 100%;
  &__selector {
    background-color: transparent;
    border-bottom: 1px solid grey;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    width: 100%;
    text-decoration: none;
    @media screen and (min-width: $desktopBreakpoint) {
      border-bottom: none;
    }
  }
  &__list {
    z-index: 10;
    list-style-type: none;
    position: absolute;
    top: 51px;
    width: 100%;
    height: 275px;
    overflow: scroll;
    @media screen and (min-width: $desktopBreakpoint) {
      height: fit-content;
      overflow: auto;
      top: 65px;
    }
  }
  &__icon {
    & svg {
      fill: #ffff;
    }
    &--margin-right {
      margin-right: 5px;
    }
    &--rotated {
      & svg {
        display: flex;
        justify-content: center;
        align-items: center;
        transform: rotate(180deg);
        transform-origin: center;
        -webkit-transform-origin: center;
        -moz-transform-origin: center;
        -o-transform-origin: center;
      }
    }
  }
}

.mobile-item {
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid grey;
  background-color: rgba(141, 203, 219, 0.856);
}
