@import "styles/variables";
.carrer-guide {
  display: flex;
  flex-direction: column;
  align-items: center;
  &__pdf {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 1500px;
  }
  &__buttons-box {
    display: flex;
    width: 300px;
    padding: 10px;
    justify-content: space-around;
    @media screen and (min-width: $desktopBreakpoint) {
      width: 700px;
    }
    &__buttons {
      width: 100px !important;
      @media screen and (min-width: $tabletBreakpoint) {
        width: 200px;
      }
      @media screen and (min-width: $desktopBreakpoint) {
        width: 300px !important;
      }
    }
  }
}
