@import 'styles/variables.scss';

.offer-page {
  width: 100%;
  margin-bottom: 30px;
  &__back-button-container {
    margin-left: 10px;
    width: 150px;
    @media screen and (min-width: $desktopBreakpoint) {
      margin-left: 50px;
    }
  }
}
.offer-section {
  position: relative;
  padding: 20px 0;
  margin-top: 5px;
  width: 100%;
  margin: auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background-color: rgba(150, 193, 31, 0.1);
  background-color: rgba(220, 239, 250, 0.61);
  @media screen and (min-width: $desktopBreakpoint) {
    flex-direction: row;
    padding: 20px 0 100px;
  }
  &__title {
    font-weight: 600;
    margin-top: 20px;
    width: 100%;
    padding: 0 20px;
    box-sizing: border-box;
    text-align: start;
    font-family: $fontFamily;
    font-size: $bigFontSize;
    position: relative;
    &::after {
      content: '';
      display: block;
      position: absolute;
      bottom: -5px;
      left: 50%;
      width: 95%;
      transform: translateX(-50%);
    }
    @media screen and (min-width: $desktopBreakpoint) {
      text-align: center;
      margin-bottom: 20px;
    }
  }
  &__id {
    position: absolute;
    top: 20px;
    left: 60px;
  }
  &__description-box {
    box-sizing: border-box;
    padding: 0 10px;
    width: 90%;
    margin: auto;
    margin-bottom: 20px;
    @media screen and (min-width: $desktopBreakpoint) {
      display: flex;
      flex-direction: column;
      max-width: 1000px;
      padding: 0;
    }
  }
  &__description {
    text-align: justify;
    font-size: 1.1rem;
    padding-left: 5px;
  }
  &__details {
    margin: auto;
    width: 90%;
    max-width: 1000px;
    list-style: none;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    position: relative;
    margin-bottom: 20px;
    padding: 20px;
    &::after {
      content: '';
      display: block;
      position: absolute;
      bottom: -5px;
      left: 50%;
      width: 90%;
      transform: translateX(-50%);
      border-bottom: 2px solid rgba(22, 21, 21, 0.1);
    }
  }
  &__details-item {
    font-family: $fontFamily;
    font-size: 1rem;
    font-weight: 600;
    color: rgb(51, 51, 51);
    width: 45%;
    margin-bottom: 10px;
  }
  &__submit-button {
    @media screen and (min-width: $desktopBreakpoint) {
      position: absolute;
      bottom: 30px;
    }
  }
}
