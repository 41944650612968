@import "styles/mixins.scss";

.date-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  &__input {
    width: 100%;
  }
  &__label {
    font-size: 1.1rem;
  }
}
