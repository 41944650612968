@import 'styles/variables';

.offer-container {
  margin: auto;
  width: 90%;
  height: fit-content;
  min-height: 250px;
  border-radius: 20px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  box-shadow: 0 0 10px rgba(128, 128, 128, 0.473);
  margin-bottom: 20px;
  padding-bottom: 20px;
  box-sizing: border-box;
  position: relative;
  @media screen and (min-width: $desktopBreakpoint) {
    max-width: 1000px;
    flex-direction: row;
    width: 1000px;
    padding-bottom: 0;
  }
  &__image-box {
    display: flex;
    justify-content: start;
    width: 100%;
    height: 100%;
    margin-bottom: 10px;
    @media screen and (min-width: $desktopBreakpoint) {
      display: flex;
      max-height: 250px;
      justify-content: start;
      width: 50%;
    }
  }
  &__image {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
  &__content-box {
    display: flex;
    align-items: center;
    flex-direction: column;
    height: 60%;
    padding: 10px 0 0;
    @media screen and (min-width: $desktopBreakpoint) {
      padding: 10px 0 10px;
      width: 60%;
      height: 100%;
      flex-wrap: wrap;
    }
  }
  &__title {
    padding: 0 30px;
    box-sizing: border-box;
    font-size: 1.5rem;
    font-family: $fontFamily;
    position: relative;
    height: fit-content;
    width: 100%;
    text-align: start;
    margin-bottom: 10px;
    &::after {
      content: '';
      width: 90%;
      height: 2px;
      background-color: $primary;
      position: absolute;
      bottom: -5px;
      left: 50%;
      transform: translateX(-50%);
    }
    @media screen and (min-width: $desktopBreakpoint) {
    }
  }
  &__details {
    margin: auto;
    width: 90%;
    max-width: 1000px;
    list-style: none;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    &--narrow {
      width: 100%;
    }
  }
  &__details-item {
    font-family: $fontFamily;
    font-size: 0.8rem;
    font-weight: 600;
    color: rgb(51, 51, 51);
    width: 45%;
    margin-bottom: 10px;
  }
  &__details-value {
    color: rgb(128, 128, 128);
  }
  &__footer {
    bottom: 20px;
    display: flex;
    justify-content: space-around;
    margin: auto;
    width: 95%;
    @media screen and (min-width: $tabletBreakpoint) {
      margin: auto 0 0 auto;
      width: 50%;
    }
    @media screen and (min-width: $desktopBreakpoint) {
      margin: 20px 0 0 auto;
      width: 70%;
      right: 10px;
    }
  }
  &__id {
    font-family: $fontFamily;
    font-weight: 600;
    font-size: 0.8rem;
    position: absolute;
    bottom: 70px;
    left: 55%;
    @media screen and (min-width: $desktopBreakpoint) {
      transform: translateX(calc(-50% + 20px));
      bottom: 30px;
      left: 50%;
    }
  }
}
