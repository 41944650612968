@import 'styles/variables';

.daily-offers-container {
  border-radius: 5px;
  width: 100%;
  height: 650px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  box-shadow: $boxShadowDark;
  background-color: rgb(253, 255, 248);
  @media screen and (min-width: $desktopBreakpoint) {
    background-color: rgba(253, 255, 248, 0.753);
    margin-bottom: 80px;
    margin-top: 50px;
    height: 420px;
    flex-direction: row;
    flex-wrap: wrap;
  }
  &__title {
    text-align: center;
    width: 100%;
    margin: 20px 0 20px;
    position: relative;
    font-family: $fontFamily;
    color: $primary;
    &::after {
      content: '';
      width: 90%;
      height: 2px;
      background-color: rgba(93, 195, 220, 0.527);
      position: absolute;
      bottom: -10px;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}
