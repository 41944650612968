@import '/src/styles/variables.scss';

.mobile-list {
  list-style-type: none;
  position: fixed;
  top: 80px;
  width: 100%;
  transform: translateY(-120%);
  transition: all 0.5s linear;
  background-color: rgba(141, 203, 219, 0.856);
  z-index: 1;
  &--open {
    transform: translateY(0);
  }
  &__item {
    border-bottom: 1px solid grey;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    width: 100%;
    text-decoration: none;
    &__link {
      text-decoration: none;
      color: white;
      font-family: $fontFamily;
      font-size: 1.25rem;
      font-weight: 600;
    }
  }
  @media screen and (min-width: $desktopBreakpoint) {
    display: none;
  }
}
