@import "styles/variables";
@import url("https://fonts.googleapis.com/css2?family=Arvo:wght@400;700&family=Merriweather&display=swap");

.home-page {
  width: 100%;
  max-width: 1500px;
  position: relative;
  margin: 10px auto 0;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  z-index: 0;
  @media screen and (min-width: $desktopBreakpoint) {
    margin: 50px auto 0;
    flex-direction: row;
  }
  &::after {
    content: "";
    position: absolute;
    width: 200%;
    right: -55px;
    top: -40px;
    height: 640px;
    border-radius: 0 15px 0 0;
    background-color: #96c11fc9;
    z-index: -1;
    @media screen and (min-width: $tabletBreakpoint) {
      height: 540px;
    }
    @media screen and (min-width: $desktopBreakpoint) {
      height: 500px;
      top: 0;
    }
  }
  &::before {
    @media screen and (min-width: $desktopBreakpoint) {
      content: "";
      position: absolute;
      width: 200%;
      left: 0px;
      top: 500px;
      height: 570px;
      border-radius: 0 0 0 0px;
      background-color: #5dc3dcb6;
      z-index: -1;
    }
  }
  &__left-column {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    @media screen and (min-width: $desktopBreakpoint) {
      height: 940px;
      margin-top: 100px;
      width: 49%;
    }
  }
  &__right-column {
    width: 100%;
    @media screen and (min-width: $desktopBreakpoint) {
      height: 940px;
      width: 49%;
    }
  }
  &__title {
    margin-bottom: 20px;
    padding: 0 20px 0 30px;
    font-family: "Arvo", serif;
    font-family: "Merriweather", serif;
    color: white;
    @media screen and (min-width: $desktopBreakpoint) {
      padding: 0 20px 0 60px;
      margin-bottom: 140px;
      transition: 2s all ease-in-out;
    }
  }
  &__image-slider {
    box-shadow: $boxShadow;
    width: 100%;
    height: 100%;
    min-height: 400px;
    margin-bottom: 40px;
    @media screen and (min-width: $desktopBreakpoint) {
      width: 100%;
      width: 620px;
      height: fit-content;
    }
  }
  &__form {
    box-shadow: $boxShadow;
    width: 100%;
    @media screen and (min-width: $desktopBreakpoint) {
      width: 100%;
      margin-bottom: 190px;
    }
  }
  &__job {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 1400px;
    text-align: center;
    margin-bottom: 70px;
    @media screen and (min-width: $desktopBreakpoint) {
      flex-direction: row;
      margin-bottom: 80px;
      align-items: flex-start;
    }

    &__wrapper {
      width: 90%;
      display: flex;
      flex-direction: column;
      align-items: center;
      @media screen and (min-width: $desktopBreakpoint) {
        width: 19%;
      }
    }

    &__pic {
      max-width: 120px;
      max-height: 100px;
      border-radius: 10px;
      background-color: white;
      margin-top: 5px;
      margin-bottom: 5px;
      @media screen and (min-width: $desktopBreakpoint) {
        width: 100px;
        height: 90px;
        margin-top: 10px;
      }
    }
    &__paragraph {
      width: 100%;
      margin: auto;
      margin-bottom: 10px;
      @media screen and (min-width: $tabletBreakpoint) {
        width: 420px;
      }
      @media screen and (min-width: $desktopBreakpoint) {
        width: 100%;
        margin-top: 10px;
      }
    }
    &__header {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 45px;
      @media screen and (min-width: $desktopBreakpoint) {
        height: 80px;
        align-items: flex-start;
      }
    }
  }
}
.home-page-recruitment {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  height: 1400px;
  text-align: center;

  @media screen and (min-width: $desktopBreakpoint) {
    width: 92%;
    height: 570px;
    margin-bottom: 60px;
  }

  &__header {
    width: 300px;
    margin: auto;
  }
  &::before {
    content: "";
    position: absolute;
    width: 100%;
    left: 0;
    top: 0;
    height: 1400px;
    border-radius: 0 0 0 0;
    background-color: #96c11fc9;
    z-index: -1;
    @media screen and (min-width: $desktopBreakpoint) {
      height: 500px;
      top: 30px;
      border-radius: 0 0 15px 0;
    }
  }
}
