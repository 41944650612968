@import "styles/variables.scss";

.rodo {
  text-align: center;
  padding: 50px;
  &__header {
    font-family: $fontFamily;
    margin-top: 10px;
  }
  &__paragraph {
    font-family: $fontFamily;
    text-align: left;
    width: 100%;
    max-width: 1000px;
    margin: auto;
  }
}
